import React from 'react';
import logo from './logo.svg';
import './App.scss';


function App() {
    return (
        <div className="App">
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col"} style={{ margin: "2% 0%"}}>
                        <img src={"ypky-logo.png"} className={"img-fluid"} />
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col"} style={{ margin: "2% 0%"}}>
                        <h2 className={"text-center"}>Content is currently being developed.</h2>
                    </div>
                </div>
            </div>
        </div>
);
}

export default App;
